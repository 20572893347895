@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --brand-primary-50: #ebebff;
    --brand-primary-100: #d2d2fe;
    --brand-primary-200: #a6a4fe;
    --brand-primary-300: #7e7cfd;
    --brand-primary-400: #524ffd;
    --brand-primary-500: #2522fc;
    --brand-primary-600: #0703e2;
    --brand-primary-700: #0502ab;
  }
}
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 148 95% 38%;
    --primary-foreground: 149 94% 94%;

    --secondary: 230 100% 16%;
    --secondary-foreground: 228 100% 97%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
@layer components {
  .fc-tailwind {
    @apply border-transparent;
  }
  .fc-tailwind .fc .fc-resource-timeline-divider {
    width: 0 !important;
  }
  .fc-tailwind .fc-datagrid-cell-cushion .fc-tailwind .fc-datagrid-cell-main,
  .fc .fc-datagrid-cell-cushion {
    @apply m-0;
    padding: 0 !important;
    width: 100% !important;
  }
  .fc-tailwind .fc-scrollgrid {
    @apply border-l-transparent;
  }
  .fc-tailwind tbody .fc-scroller-harness {
    @apply border-l border-r  border-slate-300;
  }
  .fc-tailwind .fc-timeline-header-row-chrono .fc-timeline-slot-frame {
    @apply justify-center font-prompt text-base text-gray-500 font-normal;
  }
  .fc-tailwind .fc-theme-standard td,
  .fc-theme-standard th {
    @apply border-transparent;
  }
  .fc-tailwind-container .fc .fc-toolbar.fc-header-toolbar {
    @apply mb-0;
    padding-left: 22rem;
  }
  .fc-tailwind-container .fc .fc-scroller-liquid-absolute {
    background-color: white !important;
  }
  .fc-tailwind-container .fc .fc-timeline-body {
    @apply bg-[#F5F5F5] !important;
  }
  .fc-tailwind-container .fc .fc-toolbar-title {
    @apply text-2xl font-semibold text-gray-500;
  }
  .fc-tailwind-container .fc .fc-button-primary {
    @apply bg-transparent text-gray-500 text-base w-32 transition  focus:bg-brand-primary-50 font-semibold hover:bg-brand-primary-50 border-x-transparent border-4 border-t-transparent border-b-transparent  hover:border-b-brand-primary hover:border-b-4;
  }
  .fc-tailwind-container .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    @apply border-b-4 border-b-brand-primary bg-transparent text-black border-x-transparent border-t-transparent;
  }
  .fc-tailwind-container .fc .fc-button-primary:not(:disabled):active:focus,
  .fc-tailwind-container
    .fc
    .fc-button-primary:not(:disabled).fc-button-active:focus {
    @apply shadow-none bg-brand-primary-50;
  }
  .fc-tailwind-container .fc .fc-button-primary:focus {
    @apply shadow-none;
  }
  .fc-tailwind-container .fc .fc-timeline-slot-minor {
    @apply border-hidden;
  }
  .fc-tailwind-container .fc .fc-resource-timeline-divider {
    pointer-events: none;
    opacity: 0;
  }
}
.disabled-sched-slot {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='8' height='8' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 40 40 0H20L0 20m40 20V20L20 40' fill='%23fff' fill-opacity='.5' fill-rule='evenodd'/%3E%3C/svg%3E");
}
